import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { bool, func, object, number, string } from 'prop-types';

import { propTypes } from '../../../../util/types';
import { FormattedMessage, intlShape } from '../../../../util/reactIntl';

import { ACCOUNT_SETTINGS_PAGES } from '../../../../routing/routeConfiguration';
import { useRouteConfiguration } from '../../../../context/routeConfigurationContext';

import {
  Menu,
  Avatar,
  MenuItem,
  MenuLabel,
  NamedLink,
  LinkedLogo,
  MenuContent,
  PrimaryButton,
  InlineTextButton,
} from '../../../../components';

import CustomLinksMenu from './CustomLinksMenu/CustomLinksMenu';
import TopbarSearchForm from '../TopbarSearchForm/TopbarSearchForm';

import css from './TopbarDesktop.module.css';
import { ReactComponent as Logo } from '../../../../assets/svg/bizzprofiles.svg';
import { ReactComponent as ProfileWhiteSVG } from '../../../../assets/svg/profile-white.svg';

const SignupLink = () => {
  return (
    <NamedLink name="SignupPage" className={css.topbarLink}>
      <span className={css.topbarLinkLabel}>
        <FormattedMessage id="TopbarDesktop.signup" />
      </span>
    </NamedLink>
  );
};

/* Link for Customer & Vendor Signup */
const SignupAsCustomerLink = () => {
  return (
    <NamedLink name="SignupAsCustomerPage" className={css.topbarLink}>
      <span className={css.topbarLinkLabel}>
        <FormattedMessage id="TopbarDesktop.signupAsCustomer" />
      </span>
    </NamedLink>
  );
};

const SignupAsVendorLink = () => {
  return (
    <NamedLink name="SignupAsVendorPage" className={css.topbarLink}>
      <span className={css.topbarLinkLabel}>
        <FormattedMessage id="TopbarDesktop.signupAsVendor" />
      </span>
    </NamedLink>
  );
};
/* Link for Customer & Vendor Signup */

const LoginLink = () => {
  return (
    <NamedLink name="LoginPage" className={css.topbarLinkCustom}>
      <span className={css.topbarLinkLabelCustom}>
        <FormattedMessage id="TopbarDesktop.login" />
      </span>
    </NamedLink>
  );
};

const HomeLink = () => {
  return (
    <NamedLink name="LandingPage" className={css.topbarLinkCustom}>
      <span className={css.topbarLinkLabelCustom}>Home</span>
    </NamedLink>
  );
};

const InboxLink = ({ notificationCount, currentUserHasListings }) => {
  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;

  return (
    <NamedLink
      name="InboxPage"
      params={{ tab: 'orders' }}
      className={css.topbarLink}
      // params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
    >
      <span className={css.topbarLinkLabel}>
        <FormattedMessage id="TopbarDesktop.inbox" />
        {notificationDot}
      </span>
    </NamedLink>
  );
};

const InboxMenuLink = ({ currentPageClass, notificationCount, currentUserHasListings }) => {
  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;

  return (
    <NamedLink
      name="InboxPage"
      params={{ tab: 'orders' }}
      // params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
      className={classNames(css.menuLinkCustom, currentPageClass('ManageListingsPage'))}
    >
      {/* <span className={css.menuItemBorder} /> */}
      <span>
        <FormattedMessage id="TopbarDesktop.inbox" />
        {notificationDot}
      </span>
    </NamedLink>
  );
};

const ProfileMenu = props => {
  const {
    isVendor,
    onLogout,
    currentPage,
    currentUser,
    isPremiumVendor,
    notificationCount,
    currentUserHasListings,
  } = props;

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const inboxMenuLink = (
    <InboxMenuLink
      currentPageClass={currentPageClass}
      notificationCount={notificationCount}
      currentUserHasListings={currentUserHasListings}
    />
  );

  return (
    <Menu>
      <MenuLabel className={css.profileMenuLabelCustom} isOpenClassName={css.profileMenuIsOpen}>
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
      </MenuLabel>

      <MenuContent
        className={css.profileMenuContentCustom}
        style={{
          right: '0',
          minWidth: '140',
        }}
      >
        <MenuItem key="InboxPage">{inboxMenuLink}</MenuItem>
        <MenuItem key="ManageListingsPage">
          <NamedLink
            name="ManageListingsPage"
            className={classNames(css.menuLinkCustom, currentPageClass('ManageListingsPage'))}
          >
            {/* <span className={css.menuItemBorder} /> */}
            {isVendor ? (
              <FormattedMessage id="TopbarDesktop.yourListingsLink" />
            ) : (
              <FormattedMessage id="TopbarDesktop.yourRFQsLink" />
            )}
          </NamedLink>
        </MenuItem>
        <MenuItem key="ProfileSettingsPage">
          <NamedLink
            name="ProfileSettingsPage"
            className={classNames(css.menuLinkCustom, currentPageClass('ProfileSettingsPage'))}
          >
            {/* <span className={css.menuItemBorder} /> */}
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            name="AccountSettingsPage"
            className={classNames(css.menuLinkCustom, currentPageClass('AccountSettingsPage'))}
          >
            {/* <span className={css.menuItemBorder} /> */}
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="RaisedQuotes">
          {isVendor && isPremiumVendor && (
            <NamedLink
              name="RaisedQuotes"
              className={classNames(css.menuLinkCustom, currentPageClass('RaisedQuotes'))}
            >
              {/* <span className={css.menuItemBorder} /> */}
              <FormattedMessage id="TopbarDesktop.raisedQuotesLink" />
            </NamedLink>
          )}
        </MenuItem>
        <MenuItem key="FavouritesPage">
          <NamedLink
            name="FavouritesPage"
            className={classNames(css.menuLinkCustom, currentPageClass('FavouritesPage'))}
          >
            {/* <span className={css.menuItemBorder} /> */}
            <FormattedMessage id="TopbarDesktop.favouritesLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButtonCustom} onClick={onLogout}>
            {/* <span className={css.menuItemBorder} /> */}
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  );
};

const TopbarDesktop = props => {
  const {
    intl,
    config,
    history,
    onLogout,
    className,
    currentPage,
    currentUser,
    customLinks,
    rootClassName,
    onSearchSubmit,
    isAuthenticated,
    notificationCount,
    currentUserHasListings,
    initialSearchFormValues,
  } = props;
  const routes = useRouteConfiguration();
  const [mounted, setMounted] = useState(false);
  const isPremiumVendor = currentUser?.attributes?.profile?.publicData?.isPremiumVendor;
  const isVendor = currentUser?.attributes?.profile?.publicData?.customUserType === 'vendor';

  const marketplaceName = config.marketplaceName;
  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const classes = classNames(rootClassName || css.rootCustom, className);
  const giveSpaceForSearch = customLinks == null || customLinks?.length === 0;

  const inboxLinkMaybe = authenticatedOnClientSide ? (
    <InboxLink
      notificationCount={notificationCount}
      currentUserHasListings={currentUserHasListings}
    />
  ) : null;

  const profileMenuMaybe = authenticatedOnClientSide ? (
    <ProfileMenu
      onLogout={onLogout}
      isVendor={isVendor}
      currentUser={currentUser}
      currentPage={currentPage}
      isPremiumVendor={isPremiumVendor}
      notificationCount={notificationCount}
      currentUserHasListings={currentUserHasListings}
    />
  ) : null;

  const loginLinkMaybe = isAuthenticatedOrJustHydrated ? null : <LoginLink />;
  const signupAsVendorLinkMaybe = isAuthenticatedOrJustHydrated ? null : <SignupAsVendorLink />;
  const signupAsCustomerLinkMaybe = isAuthenticatedOrJustHydrated ? null : <SignupAsCustomerLink />;
  const listingButtonLabel = intl.formatMessage({
    id: isVendor
      ? 'TopbarDesktop.listYourProductsButtonText'
      : 'TopbarDesktop.postForQuotesButtonText',
  });

  useEffect(() => {
    setMounted(true);
  }, []);

  return false ? (
    <nav className={classes}>
      <LinkedLogo
        layout="desktop"
        className={css.logoLink}
        linkToExternalSite={config?.topbar?.logoLink}
        alt={intl.formatMessage({ id: 'TopbarDesktop.logo' }, { marketplaceName })}
      />

      <TopbarSearchForm
        className={classNames(css.searchLink, { [css.takeAvailableSpace]: giveSpaceForSearch })}
        desktopInputRoot={css.topbarSearchWithLeftPadding}
        onSubmit={onSearchSubmit}
        initialValues={initialSearchFormValues}
        appConfig={config}
        history={history}
      />

      <CustomLinksMenu
        currentPage={currentPage}
        customLinks={customLinks}
        intl={intl}
        hasClientSideContentReady={authenticatedOnClientSide || !isAuthenticatedOrJustHydrated}
      />

      {inboxLinkMaybe}
      {loginLinkMaybe}
      {profileMenuMaybe}
      {signupAsVendorLinkMaybe}
      {signupAsCustomerLinkMaybe}
    </nav>
  ) : (
    <div className={css.rootCustomContainer}>
      <nav className={classes}>
        <section className={css.rootCustomLeftSection}>
          <NamedLink name="LandingPage">
            <Logo />
          </NamedLink>

          <TopbarSearchForm
            className={css.searchLink}
            routes={routes}
            history={history}
            appConfig={config}
            onSubmit={onSearchSubmit}
            initialValues={initialSearchFormValues}
          />
        </section>
        <ul className={css.rootCustomRightSection}>
          <li className={classNames(css.visibleFromFigma, css.clickbaitContainer)}>
            {!currentUser && (
              <PrimaryButton type="button" className={css.namedLinkButton}>
                <NamedLink name="SignupAsVendorPage">
                  <FormattedMessage id="TopbarDesktop.listYourProductsButtonText" />
                </NamedLink>
              </PrimaryButton>
            )}
            {!currentUser && (
              <PrimaryButton type="button" className={css.namedLinkButton}>
                <NamedLink name="SignupAsCustomerPage">
                  <FormattedMessage id="TopbarDesktop.postForQuotesButtonText" />
                </NamedLink>
              </PrimaryButton>
            )}
          </li>

          {/* {!currentUser && <li>{loginLinkMaybe}</li>} */}

          {!currentUser ? (
            <li>
              <Menu>
                <MenuLabel
                  className={css.profileMenuLabelCustom}
                  isOpenClassName={css.profileMenuIsOpen}
                >
                  <div className={css.signupButtonCustom}>
                    <ProfileWhiteSVG />
                    <FormattedMessage id="TopbarDesktop.signupOrLogin" />
                  </div>
                </MenuLabel>

                <MenuContent
                  className={css.profileMenuContentCustom}
                  style={{
                    right: '0',
                    top: '48px',
                    minWidth: '140',
                  }}
                >
                  <MenuItem key="SignupAsVendorPage">
                    <NamedLink name="SignupAsVendorPage" className={css.menuLinkCustom}>
                      <FormattedMessage id="TopbarDesktop.signupAsVendor" />
                    </NamedLink>
                  </MenuItem>

                  <MenuItem key="SignupAsCustomerPage">
                    <NamedLink name="SignupAsCustomerPage" className={css.menuLinkCustom}>
                      <FormattedMessage id="TopbarDesktop.signupAsCustomer" />
                    </NamedLink>
                  </MenuItem>

                  <MenuItem key="LoginPage">
                    <NamedLink name="LoginPage" className={css.loginLinkCustom}>
                      <FormattedMessage id="TopbarDesktop.login" />
                    </NamedLink>
                  </MenuItem>
                </MenuContent>
              </Menu>
              {/* <Menu>
                <MenuLabel
                  className={css.profileMenuLabelCustom}
                  isOpenClassName={css.profileMenuIsOpen}
                >
                  <div className={css.signupButton}>
                    <FormattedMessage id="SignupForm.signUp" />
                  </div>
                </MenuLabel>
                <MenuContent className={css.profileMenuContentCustom}>
                  <MenuItem key="SignupAsVendorPage">
                    <NamedLink name="SignupAsVendorPage" className={css.menuLinkCustom}>
                      <FormattedMessage id="TopbarDesktop.signupAsVendor" />
                    </NamedLink>
                  </MenuItem>
                  <MenuItem key="SignupAsCustomerPage">
                    <NamedLink name="SignupAsCustomerPage" className={css.menuLinkCustom}>
                      <FormattedMessage id="TopbarDesktop.signupAsCustomer" />
                    </NamedLink>
                  </MenuItem>
                </MenuContent>
              </Menu> */}
            </li>
          ) : (
            <li className={classNames(css.visibleFromFigma)}>
              <PrimaryButton type="button" className={css.namedLinkButton}>
                <NamedLink name="NewListingPage">{listingButtonLabel}</NamedLink>
              </PrimaryButton>
            </li>
          )}

          {currentUser && <li>{profileMenuMaybe}</li>}
        </ul>
      </nav>
    </div>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  config: null,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  config: object,
};

export default TopbarDesktop;
