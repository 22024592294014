/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { compose } from 'redux';

import { propTypes } from '../../../../util/types';
import { ensureCurrentUser } from '../../../../util/data';
import { injectIntl, FormattedMessage } from '../../../../util/reactIntl';
import { ACCOUNT_SETTINGS_PAGES } from '../../../../routing/routeConfiguration';

import {
  NamedLink,
  AvatarLarge,
  ExternalLink,
  InlineTextButton,
  NotificationBadge,
} from '../../../../components';

import css from './TopbarMobileMenu.module.css';

const CustomLinkComponent = ({ linkConfig, currentPage }) => {
  const { group, text, type, href, route } = linkConfig;
  const getCurrentPageClass = page => {
    const hasPageName = name => currentPage?.indexOf(name) === 0;
    const isCMSPage = pageId => hasPageName('CMSPage') && currentPage === `${page}:${pageId}`;
    const isInboxPage = tab => hasPageName('InboxPage') && currentPage === `${page}:${tab}`;
    const isCurrentPage = currentPage === page;

    return isCMSPage(route?.params?.pageId) || isInboxPage(route?.params?.tab) || isCurrentPage
      ? css.currentPage
      : null;
  };

  // Note: if the config contains 'route' keyword,
  // then in-app linking config has been resolved already.
  if (type === 'internal' && route) {
    // Internal link
    const { name, params, to } = route || {};
    const className = classNames(css.navigationLink, getCurrentPageClass(name));
    return (
      <NamedLink name={name} params={params} to={to} className={className}>
        <span className={css.menuItemBorder} />
        {text}
      </NamedLink>
    );
  }
  return (
    <ExternalLink href={href} className={css.navigationLink}>
      <span className={css.menuItemBorder} />
      {text}
    </ExternalLink>
  );
};

const TopbarMobileMenu = props => {
  const {
    intl,
    isAuthenticated,
    currentPage,
    currentUserHasListings,
    currentUser,
    notificationCount,
    customLinks,
    onLogout,
  } = props;
  const user = ensureCurrentUser(currentUser);
  const {
    publicData,
    customUserType = publicData?.customUserType,
    isPremiumVendor = publicData?.isPremiumVendor,
  } = user?.attributes?.profile || {};
  const isVendor = customUserType === 'vendor';
  const listingButtonLabel = intl.formatMessage({
    id: isVendor
      ? 'TopbarMobileMenu.listYourProductsButtonText'
      : 'TopbarMobileMenu.postForQuotesButtonText',
  });

  const extraLinks = customLinks.map(linkConfig => {
    return (
      <CustomLinkComponent
        key={linkConfig.text}
        linkConfig={linkConfig}
        currentPage={currentPage}
      />
    );
  });

  // if (!isAuthenticated) {
  //   const signup = (
  //     <NamedLink name="SignupPage" className={css.signupLink}>
  //       <FormattedMessage id="TopbarMobileMenu.signupLink" />
  //     </NamedLink>
  //   );

  //   const login = (
  //     <NamedLink name="LoginPage" className={css.loginLink}>
  //       <FormattedMessage id="TopbarMobileMenu.loginLink" />
  //     </NamedLink>
  //   );

  //   const signupOrLogin = (
  //     <span className={css.authenticationLinks}>
  //       <FormattedMessage id="TopbarMobileMenu.signupOrLogin" values={{ signup, login }} />
  //     </span>
  //   );

  //   return (
  //     <div className={css.root}>
  //       <div className={css.content}>
  //         <div className={css.authenticationGreeting}>
  //           <FormattedMessage
  //             id="TopbarMobileMenu.unauthorizedGreeting"
  //             values={{ lineBreak: <br />, signupOrLogin }}
  //           />
  //         </div>
  //         <div className={css.customLinksWrapper}>{extraLinks}</div>
  //         <div className={css.spacer} />
  //       </div>
  //       <div className={css.footer}>
  //         <NamedLink className={css.createNewListingLink} name="NewListingPage">
  //           <FormattedMessage id="TopbarMobileMenu.newListingLink" />
  //         </NamedLink>
  //       </div>
  //     </div>
  //   );
  // }

  if (!isAuthenticated) {
    const signupAsVendor = (
      <NamedLink name="SignupAsVendorPage" className={css.signupLink}>
        <FormattedMessage id="TopbarDesktop.signupAsVendor" />
      </NamedLink>
    );

    const signupAsCustomer = (
      <NamedLink name="SignupAsCustomerPage" className={css.signupLink}>
        <FormattedMessage id="TopbarDesktop.signupAsCustomer" />
      </NamedLink>
    );

    const login = (
      <NamedLink name="LoginPage" className={css.loginLink}>
        <FormattedMessage id="TopbarMobileMenu.loginLink" />
      </NamedLink>
    );

    const signupOrLogin = (
      <span className={css.authenticationLinks}>
        <FormattedMessage
          id="TopbarMobileMenu.signupOrLoginCustom"
          values={{ signupAsVendor, signupAsCustomer, login }}
        />
      </span>
    );

    return (
      <div className={css.root}>
        <div className={css.contentCustom}>
          <div className={css.upperSection}>
            <div>
              <NamedLink name="LandingPage">
                <FormattedMessage id="TopbarMobileMenu.landingPageLink" />
              </NamedLink>
            </div>

            <div>
              <NamedLink name="LoginPage">
                <FormattedMessage id="TopbarMobileMenu.loginLink" />
              </NamedLink>
            </div>
          </div>

          <div className={css.lowerSection}>
            <div>
              <NamedLink name="SignupAsVendorPage">
                <FormattedMessage id="TopbarDesktop.signupAsVendor" />
              </NamedLink>
            </div>

            <div>
              <NamedLink name="SignupAsCustomerPage">
                <FormattedMessage id="TopbarDesktop.signupAsCustomer" />
              </NamedLink>
            </div>
          </div>

          {/* <div className={css.authenticationGreeting}>
            <FormattedMessage
              id="TopbarMobileMenu.unauthorizedGreeting"
              values={{ lineBreak: <br />, signupOrLogin }}
            />
          </div>
          <div className={css.customLinksWrapper}>{extraLinks}</div>
          <div className={css.spacer} /> */}
        </div>

        <div className={css.footer}>
          <div>
            <NamedLink className={css.createNewListingLink} name="SignupAsVendorPage">
              <FormattedMessage id="TopbarMobileMenu.listYourProductsButtonText" />
            </NamedLink>
          </div>

          <div>
            <NamedLink className={css.createNewRequestLink} name="SignupAsCustomerPage">
              <FormattedMessage id="TopbarMobileMenu.postForQuotesButtonText" />
            </NamedLink>
          </div>
        </div>
      </div>
    );
  }

  const notificationCountBadge =
    notificationCount > 0 ? (
      <NotificationBadge className={css.notificationBadge} count={notificationCount} />
    ) : null;

  const displayName = user.attributes.profile.firstName;
  const inboxTab = currentUserHasListings ? 'orders' : 'sales';

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    const isInboxPage = currentPage?.indexOf('InboxPage') === 0 && page?.indexOf('InboxPage') === 0;
    return currentPage === page || isAccountSettingsPage || isInboxPage ? css.currentPage : null;
  };

  return (
    <div className={css.root}>
      <div className={css.content}>
        <AvatarLarge className={css.avatar} user={currentUser} />

        <span className={css.greeting}>
          <FormattedMessage id="TopbarMobileMenu.greeting" values={{ displayName }} />
        </span>

        <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
          <FormattedMessage id="TopbarMobileMenu.logoutLink" />
        </InlineTextButton>

        <div className={css.accountLinksWrapper}>
          <NamedLink
            className={classNames(css.inbox, currentPageClass(`InboxPage:${inboxTab}`))}
            name="InboxPage"
            params={{ tab: inboxTab }}
          >
            <FormattedMessage id="TopbarMobileMenu.inboxLink" />
            {notificationCountBadge}
          </NamedLink>

          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('ManageListingsPage'))}
            name="ManageListingsPage"
          >
            {isVendor ? (
              <FormattedMessage id="TopbarMobileMenu.yourListingsLink" />
            ) : (
              <FormattedMessage id="TopbarMobileMenu.yourRFQsLink" />
            )}
          </NamedLink>

          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <FormattedMessage id="TopbarMobileMenu.profileSettingsLink" />
          </NamedLink>

          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <FormattedMessage id="TopbarMobileMenu.accountSettingsLink" />
          </NamedLink>

          {isVendor && isPremiumVendor && (
            <NamedLink
              className={classNames(css.navigationLink, currentPageClass('RaisedQuotes'))}
              name="RaisedQuotes"
            >
              <FormattedMessage id="TopbarMobileMenu.raisedQuotesLink" />
            </NamedLink>
          )}
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('FavouritesPage'))}
            name="FavouritesPage"
          >
            <FormattedMessage id="TopbarMobileMenu.favouritesLink" />
          </NamedLink>
        </div>

        <div className={css.customLinksWrapper}>{extraLinks}</div>
        <div className={css.spacer} />
      </div>

      <div className={css.footer}>
        <NamedLink className={css.createNewListingLink} name="NewListingPage">
          {listingButtonLabel}
        </NamedLink>
      </div>
    </div>
  );
};

TopbarMobileMenu.defaultProps = { currentUser: null, notificationCount: 0, currentPage: null };

const { bool, func, number, string } = PropTypes;

TopbarMobileMenu.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
};

export default compose(injectIntl)(TopbarMobileMenu);
